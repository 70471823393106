import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const KreditFruehzeitigZurueckzahlen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Kredit frühzeitig zurückzahlen" showCalc={false} />
            <Article>
                <p>
                    Dir bist unerwarteterweise an einen größeren Geldbetrag gekommen und überlegst nun, deinen Kredit
                    vorzeitig zurückzuzahlen? Bei der diesbezüglichen Recherche stößt du aber auf widersprüchliche
                    Meinungen zu diesem Thema der{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>
                    ? Keine Sorge, im folgenden Artikel haben wir alles, was du zu Sondertilgungen wissen musst,
                    prägnant und verständlich zusammengefasst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Tilgung und Sondertilgung</h2>
                <p>
                    Zunächst müssen wir zwei Grundbegriffe klären:{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgung
                    </Link>{" "}
                    und Sondertilgung. Wie du vielleicht weißt, meint “Tilgung” jenen Teil deiner monatlichen Raten, mit
                    dem du nicht die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    bezahlst, sondern der dir tatsächlich für die Rückzahlung deiner Kreditsumme gutgeschrieben wird.
                    Wie sich die Tilgungsrate auf die Laufzeit deines Kredits auswirkt, kannst du mit einem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    ermitteln. Eine Sondertilgung liegt hingegen dann vor, wenn du deinen gesamten Kredit oder Teile
                    davon außerhalb der monatlichen Raten zurückerstattest.
                </p>
                <hr />

                <h2>Wann darf ich einen Kredit vorzeitig zurückzahlen?</h2>
                <p>
                    Bei der vorzeitigen Rückzahlung von Krediten ist es insbesondere wichtig, im Kreditvertrag
                    festgesetzte Fristen einzuhalten, ansonsten fallen zusätzliche Kosten in Form von Pönalen an.
                    Außerordentliche Rückzahlungen von maximal 10 000 € kannst du bei einem Vertrag, der nach dem 10.
                    Juni 2010 abgeschlossen wurde, in jedem Fall gebührenfrei vornehmen. Geht es um größere Beträge, so
                    musst du die entsprechenden Kündigungsfristen einhalten. Diese betragen bei variabel verzinsten
                    Krediten sechs Monate. Bei Krediten mit einer Phase fixer Zinsbindung ist es sinnvoll, deren Ablauf
                    abzuwarten, ehe man eine Sondertilgung vornimmt, da ansonsten Pönalen anfallen.
                </p>
                <hr />

                <h2>Was kostet eine vorzeitige Rückzahlung meines Kredits?</h2>
                <p>
                    Geht es um die Kosten einer vorzeitigen Rückzahlung, so ist der Zeitpunkt entscheidend, zu dem du
                    den Kreditvertrag abgeschlossen hast. Im Falle von Verträgen, die nach dem 10. Juni 2010
                    unterzeichnet wurden, ist bei einer Sondertilgung höchstens 1 % der frühzeitig rückerstatteten Summe
                    zu bezahlen. Beträgt die restliche Laufzeit deines Kredits 12 Monate oder weniger, so können sogar
                    maximal 0,5 % des Restbetrags in Form einer Pönale verlangt werden. Vor diesem Datum abgeschlossene
                    Veträge kennen allerdings durchaus noch Pönalen in Höhe von 4 oder 5 % des Sondertilgungsbetrags.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Hier geht es zum kostenlosen Erstgespräch!
                    </a>
                </p>
                <hr />

                <h2>Wann lohnt es sich, meinen Kredit frühzeitig zurückzuzahlen?</h2>
                <p>
                    Ausschlaggebend für eine vorzeitige Kreditrückzahlung ist eine einfache Kosten-Nutzen-Rechnung:
                    Sondertilgungen sind dann sinnvoll, wenn die Ersparnis an Zinsen die Kosten übersteigt, die in Form
                    von Strafzahlungen anfallen. Wenn also erwartbar ist, dass die Zinsen deines Kredites in Zukunft
                    (deutlich) steigen werden und du über das entsprechende Kapital zur vorzeitigen Tilgung verfügst,
                    dann könnte eine Sondertilgung die richtige Option sein. Lass dich aber in jedem Fall gut beraten,
                    unsere Finanzierungsexpert:innen stehen dir natürlich auch bei dieser Frage gern zur Seite!
                </p>
            </Article>

            <BreadcrumbList page={"kreditFruehzeitigZurueckzahlen"}></BreadcrumbList>
            <ArticleStructuredData page={"kreditFruehzeitigZurueckzahlen"} heading={"Kredit frühzeitig zurückzahlen"} />
        </Layout>
    );
};

export default KreditFruehzeitigZurueckzahlen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kredit-fruehzeitig-zurueckzahlen", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
